.App {
    text-align: center;
}

.App-header {
    background-color: #282c34;
    min-height: 50vh;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.textColor, .nav-link {
    color: #ffffff;
}

html, body {
    height: 100%;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  
  }
  body {
    display: flex;
    flex-direction: column;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  }
  .content {
    flex: 1 0 auto;
    padding: 20px;
  
  }
  .footer {
    flex-shrink: 0;
    padding: 10px;
    bottom: 0;
    left: 0;
    color: #707070;
    right: 0;
    position: relative;
    width:100%;
  }
  
  .content-center{
    display: flex;
    justify-content: center;
    align-items: center;
  }
.footerNav {
    text-decoration: none;
    color: #000000;
}

.header {
    background-color: #282c34;
    min-height: 30vh;
    height: 55vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}


* {
    margin: 0;
    padding: 0;
}

.pages {
    color: #316685;
    text-align: center;
    font-size: calc(1.5rem + 2vw);
    margin-top: 10%;
}

.navbar {
    background-color: #1f5156;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 20;
}

.nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1500px;
}

.main-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.nav-logo {
    color: #f5b921;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;
}

.nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
}

.nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
}

.fa-code {
    margin-left: 1rem;
}

.nav-item {
    line-height: 40px;
    margin-right: 1rem;
}

.nav-item2 {
    line-height: 40px;
    margin-right: 30rem;
    margin-left: 1rem;
}

.nav-item3 {
    line-height: 50px;
    margin-right: 40rem;
    margin-left: 1rem;
}

.nav-item2:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item2:hover:after {
    width: 100%;
    background: #ffdd40;
}

.nav-item2.active {
    color: #ffffff;
}

.nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
    width: 100%;
    background: #ffdd40;
}

.nav-item.active {
    color: #ffdd40;
    border: 1px solid #ffdd40;
}

.nav-icon {
    display: none;
}

Card{
    flex-direction: row;

}

.headerSearch {
    background-color: #ffffff;
    width: 100%;
    margin: 20px;
    align-items: center;
    justify-content: center;
    color: rgb(0, 0, 0);
}

.imgs{
    width: 50%;
}

.slider{
    width: 50%;
    height: 50%;
}

.imgss{
    width: 100%;
}

.maps {
    width: 100%;
    height: 100%;
}

.google-map {
    display: flex;
    position: relative;
}
  
    iframe {
      height: auto;
      width: 100%;
    }
  
    [data-state-details] {
      color: #6b6763;
      height: 1.5em;
      margin-top: -0.75em;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      position: absolute;
      text-align: center;
      top: 50%;
      width: 100%;
    }
  
 


@media screen and (max-width: 600px) {
    .header {
        background-color: #282c34;
        height: 25vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: calc(2px + 2vmin);
        color: white;
    }
    .imgs{
        width: 100%;
        display: flex;
        flex-direction: row;
    }
    

    .headerSearch {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 5%;
        margin-right: 5%;
        justify-content: center;
        font-size: calc(2px + 2vmin);
        color: rgb(0, 0, 0);
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1pxsolid #fff;
        position: absolute;
        top: 80px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
    }
    .nav-menu.active {
        background: #1f5156;
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }
    .nav-item .active {
        color: #ffdd40;
        border: none;
    }
    .nav-links {
        padding: 1.5rem;
        width: 100%;
        display: table;
    }
    .nav-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #ffdd40;
    }
}